import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';

import AdminTable from 'layouts/AdminTable';
import FormTextInput from 'components/FormTextInput/FormTextInput';

import { get, post, put } from 'helpers/apiHelpers';

import { toast } from 'react-toastify';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { FormLabel } from '@material-ui/core';
import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import CustomInput from 'components/CustomInput/CustomInput';
import SelectInput from 'components/FormSelect/SelectInput';
import LabeledCheckbox from 'components/Checkbox/LabeledCheckbox';
import Datetime from 'react-datetime';
import useDebounce from 'hooks/common/useDebounce';
import moment from 'moment';
import makeStyles from '@material-ui/styles/makeStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

const defaultFormValues = {
  name: '',
  postCode: '',
  city: '',
  street: '',
  buildNumber: '',
  placeNumber: '',
  deliveryHourFrom: '',
  deliveryHourTo: '',
  comment: '',
};

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
  textInput: {
    marginTop: 12,
  },
  lastLineInputs: {
    marginTop: 4,
  },
});

const Form = ({ isEdit = false }) => {
  const { t } = useTranslation();

  const match = useRouteMatch();
  const history = useHistory();

  const [formValues, setFormValues] = useState(defaultFormValues);
  const [cities, setCities] = useState([]);
  const [streets, setStreets] = useState([]);
  const [buildingNumbers, setBuildingNumbers] = useState([]);
  const [deliveryHours, setDeliveryHours] = useState([]);
  const [isPostCodeValid, setIsPostCodeValid] = useState(isEdit);
  const [selectedHour, setSelectedHour] = useState(null);
  const [areDeliveryHoursCustom, setAreDeliveryHoursCustom] = useState(false);

  const elementId = match.params.id;
  const selectedRegionForValidations = useSelector(
    state => state.Brands.selectedRegionForValidations
  );

  const classes = useStyles();

  const fetchCities = postCode =>
    get('/frontend/zone-post-codes/cities', { postCode });

  const fetchStreets = ({ postCode, city }) =>
    get('/frontend/zone-post-codes/cities/streets', { postCode, city });

  const fetchBuildingNumbers = ({ postCode, city, street }) =>
    get('/frontend/zone-post-codes/cities/streets/buildings', {
      postCode,
      city,
      street,
    });

  const getDeliveryHoursOptions = deliveryHoursData => {
    if (isEmpty(deliveryHoursData)) {
      return [];
    }

    const hours = deliveryHoursData.map(hour => {
      const formattedHourFrom = hour?.hourFrom
        ? moment(hour.hourFrom).format('HH:mm')
        : null;
      const formattedHourTo = hour?.hourTo
        ? moment(hour.hourTo).format('HH:mm')
        : null;

      return {
        '@id': hour['@id'],
        hourFrom: hour?.hourFrom,
        hourTo: hour?.hourTo,
        label: [
          hour?.hourFrom && [t('$*common.from'), formattedHourFrom].join(' '),
          hour?.hourTo && [t('$*common.to'), formattedHourTo].join(' '),
        ].join(' '),
      };
    });

    return hours;
  };

  const fetchDeliveryHours = ({ city, street, postCode, building }) =>
    get('/frontend/delivery-hours', {
      postCode,
      city,
      street,
      building,
    });

  const handleInputChange = (event, obj) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event?.target?.value ?? obj,
    });
  };

  const handlePostCodeInput = async event => {
    const postCode = event.target.value;
    const regexp = selectedRegionForValidations?.postCodeRegexp;
    const regexpTest = new RegExp(`${regexp}$`);
    const isPostCodeValid = null === regexp || regexpTest.test(postCode);

    setIsPostCodeValid(isPostCodeValid);
    setFormValues(prev => ({
      ...prev,
      postCode,
    }));

    if (isPostCodeValid) {
      try {
        const citiesData = await fetchCities(postCode);
        const newCities = citiesData?.allowCustom
          ? []
          : (citiesData?.cities?.['hydra:member'] ?? []).map(currentCity => ({
              '@id': currentCity,
              label: currentCity,
            }));

        const isCurrentCityValid =
          !isEmpty(formValues.city) &&
          newCities.map(({ label }) => label).includes(formValues.city);

        if (!isCurrentCityValid) {
          setFormValues(prev => ({
            ...prev,
            city: '',
          }));
        }
        setCities(newCities);
      } catch ({ response }) {
        const isPostCodeInvalid = (response?.data?.violations ?? []).some(
          ({ propertyPath }) => propertyPath === 'postCode'
        );

        if (isPostCodeInvalid) {
          setIsPostCodeValid(false);
        }
      }
    }
  };

  const handlePostCodeBlur = () => {
    if (!isPostCodeValid) {
      toast.error(t('form.postCodeIncorrect'));
    }
  };

  const handleCitySelect = async event => {
    const streetsData = await fetchStreets({
      postCode: formValues.postCode,
      city: event.target.value,
    });
    const newStreets = streetsData?.allowCustom
      ? []
      : (streetsData?.streets?.['hydra:member'] ?? []).map(currentStreet => ({
          '@id': currentStreet,
          label: currentStreet,
        }));

    setFormValues(() => ({
      ...formValues,
      city: event.target.value,
      street:
        newStreets.map(({ label }) => label).includes(formValues.street) ||
        isEmpty(newStreets)
          ? formValues.street
          : '',
    }));
    setStreets(newStreets);
  };

  const handleStreetSelect = async event => {
    const buildingNumbersData = await fetchBuildingNumbers({
      postCode: formValues.postCode,
      city: formValues.city,
      street: event.target.value,
    });
    const newBuildingNumbers = buildingNumbersData?.allowCustom
      ? []
      : (buildingNumbersData?.buildings?.['hydra:member'] ?? []).map(
          currentBuildingNumber => ({
            '@id': currentBuildingNumber,
            label: currentBuildingNumber,
          })
        );

    setFormValues(() => ({
      ...formValues,
      street: event.target.value,
      buildNumber:
        newBuildingNumbers
          .map(({ label }) => label)
          .includes(formValues.buildNumber) || isEmpty(newBuildingNumbers)
          ? formValues.buildNumber
          : '',
    }));
    setBuildingNumbers(newBuildingNumbers);
  };

  const handleToggleDeliveryHours = () => {
    setAreDeliveryHoursCustom(prev => !prev);
    setSelectedHour(null);
    setFormValues(prev => ({
      ...prev,
      deliveryHourFrom: '',
      deliveryHourTo: '',
    }));
  };

  const handleHourFromChange = event => {
    setFormValues({ ...formValues, deliveryHourFrom: event.format('HH:mm') });
  };

  const handleHourToChange = event => {
    setFormValues({ ...formValues, deliveryHourTo: event.format('HH:mm') });
  };

  const handleHoursSelect = (_, obj) => {
    setSelectedHour(obj);
    setFormValues({
      ...formValues,
      deliveryHourFrom: obj.hourFrom,
      deliveryHourTo: obj.hourTo,
    });
  };

  const {
    name,
    postCode,
    city,
    street,
    buildNumber,
    placeNumber,
    deliveryHourFrom,
    deliveryHourTo,
    comment,
  } = formValues;

  const areRequiredFieldsFilled =
    name &&
    postCode &&
    city &&
    street &&
    buildNumber &&
    deliveryHourFrom &&
    deliveryHourTo;

  const handleSubmit = () => {
    if (!areRequiredFieldsFilled) {
      return toast.error(t('form.requiredFieldsError'));
    }

    if (!isPostCodeValid) {
      return toast.error(t('form.postCodeIncorrect'));
    }

    const data = {
      value: name,
      address: {
        postCode,
        city,
        street,
        buildNumber,
        placeNumber,
        deliveryHourFrom,
        deliveryHourTo,
        comment,
      },
    };

    const action = isEdit
      ? put('pick-up-points/' + elementId, data)
      : post('pick-up-points', data);

    action.then(() => history.push('/admin/pick-up-points'));
  };

  const fetchEditData = async () => {
    const { value, address } = await get('pick-up-points/' + elementId);

    if (isEmpty(address)) {
      setFormValues(prev => ({
        ...prev,
        name: value,
      }));

      return;
    }

    try {
      const citiesData = await fetchCities(address.postCode);

      setCities(
        citiesData?.allowCustom
          ? []
          : (citiesData?.cities?.['hydra:member'] ?? []).map(currentCity => ({
              '@id': currentCity,
              label: currentCity,
            }))
      );

      Promise.all([
        fetchStreets({
          postCode: address.postCode,
          city: address.city,
        }),
        fetchBuildingNumbers({
          postCode: address.postCode,
          city: address.city,
          street: address.street,
        }),
      ]).then(([streetsData, buildingNumbersData]) => {
        setStreets(
          streetsData?.allowCustom
            ? []
            : (streetsData?.streets?.['hydra:member'] ?? []).map(
                currentStreet => ({
                  '@id': currentStreet,
                  label: currentStreet,
                })
              )
        );
        setBuildingNumbers(
          buildingNumbersData?.allowCustom
            ? []
            : (buildingNumbersData?.buildings?.['hydra:member'] ?? []).map(
                currentBuildingNumber => ({
                  '@id': currentBuildingNumber,
                  label: currentBuildingNumber,
                })
              )
        );
      });
    } finally {
      const zoneDeliveryHours = address.selectedDeliveryHour.zoneDeliveryHours;

      setSelectedHour(zoneDeliveryHours);
      setAreDeliveryHoursCustom(!zoneDeliveryHours);
      setDeliveryHours(getDeliveryHoursOptions(address.deliveryHourOptions));

      const formatDeliveryHour = hour => {
        if (!hour) {
          return '';
        }

        return moment(hour).format('HH:mm');
      };

      setFormValues({
        name: value,
        postCode: address.postCode,
        city: address.city,
        street: address.street,
        buildNumber: address.buildNumber,
        placeNumber: address.placeNumber,
        deliveryHourFrom: formatDeliveryHour(
          address.selectedDeliveryHour.hourFrom
        ),
        deliveryHourTo: formatDeliveryHour(address.selectedDeliveryHour.hourTo),
        comment: address.comment,
      });
    }
  };

  const debouncedCity = useDebounce(formValues.city);
  const debouncedStreet = useDebounce(formValues.street);
  const debouncedPostCode = useDebounce(formValues.postCode);
  const debouncedBuildNumber = useDebounce(formValues.buildNumber);

  useEffect(async () => {
    if (
      isPostCodeValid &&
      !areDeliveryHoursCustom &&
      !isEmpty(debouncedCity) &&
      !isEmpty(debouncedStreet) &&
      !isEmpty(debouncedPostCode) &&
      !isEmpty(debouncedBuildNumber)
    ) {
      const { hours } = await fetchDeliveryHours({
        city: debouncedCity,
        street: debouncedStreet,
        postCode: debouncedPostCode,
        building: debouncedBuildNumber,
      });

      setDeliveryHours(getDeliveryHoursOptions(hours));
    }
  }, [
    areDeliveryHoursCustom,
    isPostCodeValid,
    debouncedPostCode,
    debouncedCity,
    debouncedStreet,
    debouncedBuildNumber,
  ]);

  useEffect(() => {
    if (isEdit) {
      fetchEditData();
    }
  }, []);

  return (
    <>
      <h3>{isEdit ? t('pickupPoints.edit') : t('pickupPoints.addNew')}</h3>
      <AdminTable title={t('pickupPoints.form.clientName')}>
        <FormTextInput
          label={t('form.field.name') + '*'}
          classes={classes}
          name="name"
          value={formValues.name}
          handleChange={handleInputChange}
          inputSize={4}
        />
      </AdminTable>
      <AdminTable title={t('pickupPoints.form.address')}>
        <GridContainer>
          <GridItem sm={6}>
            <FormLabel className={classes.labelHorizontal}>
              {t('common.address.postCode')} *
            </FormLabel>
            <FormTextInputNoGrid
              classes={classes}
              overrideClasses={{ root: classes.textInput }}
              customInput={FormTextInputNoGrid}
              value={formValues.postCode}
              success={isPostCodeValid}
              error={!isPostCodeValid}
              onChange={handlePostCodeInput}
              onBlur={handlePostCodeBlur}
              name="postCode"
            />
          </GridItem>
          <GridItem sm={6}>
            {isEmpty(cities) ? (
              <>
                <FormLabel className={classes.labelHorizontal}>
                  {t('common.address.town')} *
                </FormLabel>
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  overrideClasses={{ root: classes.textInput }}
                  maxLength={32}
                  inputProps={{
                    name: 'city',
                    value: formValues.city,
                    onChange: handleInputChange,
                  }}
                  disabled={!isPostCodeValid}
                />
              </>
            ) : (
              <SelectInput
                classes={classes}
                label={`${t('common.address.town')} *`}
                options={cities}
                value={formValues.city}
                mapBy="label"
                trackBy="@id"
                name="city"
                handleChange={handleCitySelect}
                id="city"
                disabled={isEmpty(cities)}
              />
            )}
          </GridItem>
          <GridItem sm={6}>
            {isEmpty(cities) || isEmpty(streets) ? (
              <>
                <FormLabel className={classes.labelHorizontal}>
                  {t('common.address.street')} *
                </FormLabel>
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  overrideClasses={{ root: classes.textInput }}
                  maxLength={32}
                  inputProps={{
                    name: 'street',
                    value: formValues.street,
                    onChange: handleInputChange,
                  }}
                  disabled={isEmpty(formValues.city)}
                />
              </>
            ) : (
              <SelectInput
                classes={classes}
                label={`${t('common.address.street')} *`}
                options={streets}
                value={formValues.street}
                mapBy="label"
                trackBy="@id"
                name="street"
                handleChange={handleStreetSelect}
                id="street"
                disabled={isEmpty(streets)}
              />
            )}
          </GridItem>
          <GridItem sm={3}>
            {isEmpty(cities) || isEmpty(streets) || isEmpty(buildingNumbers) ? (
              <>
                <FormLabel className={classes.labelHorizontal}>
                  {t('common.address.buildingNo')} *
                </FormLabel>
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  overrideClasses={{ root: classes.textInput }}
                  maxLength={16}
                  inputProps={{
                    name: 'buildNumber',
                    value: formValues.buildNumber,
                    onChange: handleInputChange,
                  }}
                  disabled={isEmpty(formValues.street)}
                />
              </>
            ) : (
              <SelectInput
                classes={classes}
                label={`${t('common.address.buildingNo')} *`}
                options={buildingNumbers}
                value={formValues.buildNumber}
                mapBy="label"
                trackBy="@id"
                name="buildNumber"
                handleChange={handleInputChange}
                id="buildNumber"
                disabled={isEmpty(buildingNumbers)}
              />
            )}
          </GridItem>
          <GridItem sm={3}>
            <FormLabel className={classes.labelHorizontal}>
              {t('common.address.localNo')}
            </FormLabel>
            <CustomInput
              formControlProps={{ fullWidth: true }}
              overrideClasses={{ root: classes.textInput }}
              maxLength={16}
              inputProps={{
                name: 'placeNumber',
                value: formValues.placeNumber,
                onChange: handleInputChange,
              }}
            />
          </GridItem>
          {areDeliveryHoursCustom ? (
            <>
              <GridItem sm={3}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('common.address.deliveryHourFrom')} *
                </FormLabel>
                <div className={classes.lastLineInputs}>
                  <Datetime
                    inputProps={{ onChange: () => false }}
                    dateFormat={false}
                    value={formValues.deliveryHourFrom}
                    onChange={handleHourFromChange}
                  />
                </div>
              </GridItem>
              <GridItem sm={3}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('common.address.deliveryHourTo')} *
                </FormLabel>
                <div className={classes.lastLineInputs}>
                  <Datetime
                    inputProps={{ onChange: () => false }}
                    dateFormat={false}
                    value={formValues.deliveryHourTo}
                    onChange={handleHourToChange}
                  />
                </div>
              </GridItem>
            </>
          ) : (
            <GridItem sm={6}>
              <FormLabel className={classes.labelHorizontal}>
                {t('common.address.deliveryHour')} *
              </FormLabel>
              <SelectInput
                classes={classes}
                options={deliveryHours}
                value={selectedHour}
                mapBy="label"
                trackBy="@id"
                name="selectedHour"
                handleChange={handleHoursSelect}
                id="selectedHour"
                disabled={isEmpty(deliveryHours)}
                noGrid={true}
              />
            </GridItem>
          )}
          <GridItem sm={6}>
            <FormLabel className={classes.labelHorizontal}>
              {t('common.address.notes')}
            </FormLabel>
            <CustomInput
              overrideClasses={{ root: classes.lastLineInputs }}
              formControlProps={{ fullWidth: true }}
              maxLength={500}
              inputProps={{
                multiline: true,
                name: 'comment',
                value: formValues.comment,
                onChange: handleInputChange,
              }}
            />
          </GridItem>
          <GridItem sm={6}>
            <LabeledCheckbox
              name="customDeliveryHours"
              onClick={handleToggleDeliveryHours}
              checked={areDeliveryHoursCustom}
              label={t('form.customDeliveryHours')}
            />
          </GridItem>
        </GridContainer>
      </AdminTable>
      <FormControlStickyButton
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath="/admin/pick-up-points"
        handleSubmit={handleSubmit}
        customOffsetSmall="10px"
        customOffsetLarge="10px"
        isFixedToBottom={true}
      />
    </>
  );
};

export default Form;
